<template>

  <div id="page-user-list">

    <div class="vx-card p-6" v-show="loaded">

      <!-- Header -->
      <div class="flex justify-between flex-wrap items-center">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px; align-items: center;">
          <v-select v-model="year" :clearable="false" :options="yearOptions"></v-select>
          <!-- Button Add -->
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="addItem">Add Item</vs-button>
        </div>

      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>

    </div>

    <!--  Popup Brand  -->
    <vs-popup classContent="popup-example" :title="`${mode.charAt(0).toUpperCase() +  mode.slice(1)} ${mode === 'detail' ? 'Report' : 'Item'}`" :active.sync="popup">

      <!-- show form when mode is not detail -->
      <div v-if="mode == 'edit' || mode == 'add'">
        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Date') }}</small>
            <datepicker format="d/MM/yyyy" :value="form.jadwal" @input="form.jadwal = $moment($event)"></datepicker>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('Company') }}</small>
            <v-select v-model="form.company" :clearable="false" label="nama" :options="companyOptions"></v-select>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('ContactName') }}</small>
            <v-select v-model="form.nama_contact" :clearable="false" label="nama_karyawan" :options="customersOptions"></v-select>
          </div>
        </div>

        <div class="vx-row mb-3" v-if="$route.params.tipe == 'mom'">
          <div class="vx-col md:w-1/1 w-full">
            <small class="mb-1">{{ $i18n.t('ProjectName') }}</small>
            <v-select v-model="form.project_name" :clearable="false" label="project_name" :options="forecastOptions"></v-select>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Subjek')" v-model="form.subjek" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input class="w-full mb-3" :label="$i18n.t('Location')" v-model="form.lokasi" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <label for="pembahasan" class="vs-input--label">{{$i18n.t('Discussion')}}</label>
            <vs-textarea rows="6" id="pembahasan" class="w-full mb-3" v-model="form.pembahasan" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <label for="kesimpulan" class="vs-input--label">{{$i18n.t('Summary')}}</label>
            <vs-textarea rows="6" id="kesimpulan" class="w-full mb-3" v-model="form.kesimpulan" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/1 w-full">
            <vs-input :label="$i18n.t('Attachment')" type="file" @change="handleFileChange($event.target.name, $event.target.files)" name="file" class="w-full mt-4" />
          </div>
        </div>

        <div class="mt-5">
          <vs-button color="primary" type="filled" icon-pack="feather" icon="icon-save" @click="storeItem">
            {{ $i18n.t('Save') }}
          </vs-button>
        </div>
      </div>

      <!-- show detail -->
      <div class="grid grid-cols-2 justify-center" v-else>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
              {{ $i18n.t('Company') }}
          </h1>
          <span class="block">
            {{ itemSelected.company ? itemSelected.company : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('ProjectName') }}
          </h1>
          <span class="block">
            {{ itemSelected.nama_project ? itemSelected.nama_project : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('ContactName') }}
          </h1>
          <span class="block">
            {{ itemSelected.nama_contact ? itemSelected.nama_contact : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Username') }}
          </h1>
          <span class="block">
            {{ itemSelected.nama_user ? itemSelected.nama_user : '-'}}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Subject') }}
          </h1>
          <span class="block">
            {{ itemSelected.subjek ?  itemSelected.subjek : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('Location') }}
          </h1>
          <span class="block">
            {{ itemSelected.lokasi ? itemSelected.lokasi : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('MeetingDate') }}
          </h1>
          <span class="block">
            {{ itemSelected.jadwal ?  itemSelected.jadwal : '-' }}
          </span>
        </div>
        <div class="container mb-4">
          <h1 class="text-lg font-semibold mb-2">
            {{ $i18n.t('CreatedAt') }}
          </h1>
          <span class="block">
            {{ itemSelected.input ? itemSelected.input : '-' }}
          </span>
        </div>
       
        <div class="container col-start-1 col-end-3 mt-2 mb-4">
          <h1 class="text-lg text-center font-semibold mb-2">
            {{ $i18n.t('Discussion') }}
          </h1>
          <span class="block" v-html="itemSelected.pembahasan.replace(/\n/g, '<br>')" />
        </div>
        <div class="container col-start-1 col-end-3 mt-2 mb-4" v-if="itemSelected.kesimpulan != null">
          <h1 class="text-lg text-center font-semibold mb-2">
            {{ $i18n.t('Summary') }}
          </h1>
          <span class="block" v-html="itemSelected.kesimpulan.replace(/\n/g, '<br>')" />
        </div>
        <div class="mt-5 col-start-1 col-end-3 flex justify-center" v-if="itemSelected.upload">
          <vs-button color="primary" type="border" icon-pack="feather" icon="icon-file" @click="openAttachment(itemSelected.upload)">
            {{ $i18n.t('Attachment') }}
          </vs-button>
        </div>
        <div class="mt-5 col-start-1 col-end-3" v-else>
          <h1 class="text-lg text-center text-danger font-semibold mb-2">
            {{ $i18n.t('No Attachment') }}
          </h1>
        </div>
      </div>

    </vs-popup>

  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import Datepicker from "vuejs-datepicker";

import axios from '@/axios';
import recipe from "@/recipe";

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'
import moment from "moment";


export default {
  components: {
    AgGridVue,
    vSelect,
    Datepicker,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      year: '2021',
      yearOptions: [],

      form: {
        file: '',
      },
      popup: false,
      mode: 'add',
      itemSelected: false,
      customersOptions: [],
      companyOptions: [],
      forecastOptions: [],

      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Date'),
          field: 'jadwal',
          filter: true,
          headerClass: 'text-center',
          cellClass: 'text-center'
        },
        {
          headerName: this.$i18n.t('ContactName'),
          field: 'nama_contact',
          filter: true,
          cellRendererFramework: 'CellRendererLink',
          cellRendererParams: {
            showItem: this.showItem.bind(this),
          },
        },
        {
          headerName: this.$i18n.t('Location'),
          field: 'lokasi',
          filter: true,
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            showItem: this.showItem.bind(this),
            editItem: this.editItem.bind(this),
            deleteItem: this.deleteItem.bind(this),
          },
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    openAttachment(file){
      window.open(`${recipe}/upload/sales/visit-reports/${file}`)
    },
    init(){
      this.year = new Date().getFullYear();

      for(let i = 2015; i <= parseInt(this.year); i++){
        this.yearOptions.push(i.toString())
      }

      this.yearOptions.reverse()
    },

    loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },
    // on Change
    handleFileChange (event, files) {
      this.form.file = files[0];
    },

    // append form data
    appendFormData (obj) {
      let formData = new FormData();

      Object.keys(obj).forEach(key => {
        formData.append(key, obj[key]);
      });

      return formData;
    },
    getData () {
      let params = {
        tipe: this.$route.params.tipe,
        year: this.year,
      };

      axios.get('/sales/visit-report', {params}).then(resp => this.itemsData = resp.data ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
      .finally(() => {
        this.onLoaded()
      })
    },
    showItem(item){
      this.popup = true;
      this.mode = 'detail';
      this.itemSelected = item
    },
    addItem () {
      this.popup = true;
      this.mode = 'add';

      let date = moment(new Date()).format('DD');
      let month = moment(new Date()).format('MM');

      this.form.jadwal = this.year + '-' + month + '-' + date;
    },
    editItem (item) {
      this.popup = true;
      this.mode = 'edit';
      this.itemSelected = item;

      this.form = {
        jadwal: item.jadwal,
        company: item.company,
        nama_contact: item.nama_contact,
        lokasi: item.lokasi,
        pembahasan: item.pembahasan,
        kesimpulan: item.kesimpulan,
        file: item.upload,
        tipe: item.tipe,
        subjek: item.subjek,
      };

      if(this.$route.params.tipe == "mom"){
        this.form.project_name = item.nama_project
      }
    },
    deleteItem (item) {
      axios.delete('/sales/visit-report/' + item.id).then(resp => {
        this.$swal({
          title: 'Successful',
          text: resp.data.message,
          icon: 'success'
        }).then(() => {
          this.getData();
        });
      }).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    storeItem () {
      if (this.mode == 'add') {

        let body = {
          jadwal: this.form.jadwal,
          company: this.form.company.nama,
          nama_contact: this.form.nama_contact.nama_karyawan,
          lokasi: this.form.lokasi,
          pembahasan: this.form.pembahasan,
          kesimpulan: this.form.kesimpulan,
          upload: this.form.file,
          subjek: this.form.subjek,
          tipe: this.$route.params.tipe,
        };

        if (body.tipe == 'mom'){
          if(this.form.project_name){
            body.nama_project = this.form.project_name.project_name;
          }
        }

        let params = new FormData();

        Object.keys(body).forEach(key => {
          params.append(key, body[key]);
        });

        // for (var value of params.values()) {
        //   console.log(value);
        // }

        axios.post('/sales/visit-report',params, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
          }
        }).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.mode = false;
          });
          this.getData();
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
      else if (this.mode == 'edit') {
        let body = {
          jadwal: this.form.jadwal,
          company: this.form.company.nama == null ? this.form.company : this.form.company.nama,
          nama_contact: this.form.nama_contact.nama_karyawan == null ? this.form.nama_contact : this.form.nama_contact.nama_karyawan,
          lokasi: this.form.lokasi,
          pembahasan: this.form.pembahasan,
          kesimpulan: this.form.kesimpulan,
          upload: this.form.file,
          tipe: this.$route.params.tipe,
          subjek: this.form.subjek,
          _method: 'PUT',
        };

        if (body.tipe == 'mom'){
          if(this.form.project_name){
            body.nama_project = this.form.project_name.project_name == null ? this.form.project_name : this.form.project_name.project_name;
          }
        }

        body = this.appendFormData(body);

        axios.post('/sales/visit-report/' + this.itemSelected.id, body).then(resp => {
          this.popup = false;

          this.$swal({
            title: 'Successful',
            text: resp.data.message,
            icon: 'success'
          }).then(() => {
            this.mode = false;
          });
          this.getData();
        }).catch(err => {
          this.$vs.notify({
            title:'Opps something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }


    },

    getCustomers () {

      axios.get('/sales/customer-contacts').then(resp => this.customersOptions = resp.data ).catch(err => {
        this.$vs.notify({
          title:'Oops something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },

    onLoaded(){
      this.loaded = true;
      this.$vs.loading.close();
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit();
  },
  watch: {
    year () {
      this.getData();
    },
    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {
          file: '',
        };
      }
    },

    form: {
      deep: true,
      handler(){
        let params = {
          company: this.form.company.nama
        }

        axios.get('/sales/customer-contacts', { params }).then(resp => this.customersOptions = resp.data ).catch(err => {
          this.$vs.notify({
            title:'Oops something error',
            text: err.data.message,
            color:'danger'
          })
        })
      }
    }
  },
  created () {

    this.init();
    this.loading();

    axios.get('/sales/company', { }).then(resp => this.companyOptions = resp.data ).catch(err => {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    })

    this.getCustomers();

    axios.get('/sales/forecasts', { }).then(resp => this.forecastOptions = resp.data ).catch(err => {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    })

    this.getData();

  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
